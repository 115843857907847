// Generated by Framer (c974371)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Row from "./tmPKwOouH";
const RowFonts = getFonts(Row);

const cycleOrder = ["aIkCtC1nG"];

const serializationHash = "framer-DnOdw"

const variantClassNames = {aIkCtC1nG: "framer-v-1tjkan7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({answer, answer2, answer3, height, id, question, question2, question3, width, ...props}) => { return {...props, eX0r1son7: question3 ?? props.eX0r1son7 ?? "How do I add these to my Framer site?", EY2wqFsIg: answer3 ?? props.EY2wqFsIg ?? "If you live in Mallorca it is always good to share a coffee. Tell us when and we will meet. ", l5idZHupd: answer2 ?? props.l5idZHupd ?? "Upon subscribing, you will be matched with a top-tier designer. Each designer in our team is personally chosen by our Founder and Creative Director, Luis Curti, boasting over 15 years of expertise in the digital and branding sectors.", sBCP4a1FS: question ?? props.sBCP4a1FS ?? "Where are your offices located?", x8FF61TOf: answer ?? props.x8FF61TOf ?? "We are located in Palma de Mallorca. In Santa Catalina. \nSant Magi 24. 07013.\nAnd we are open for appointments on Fridays only.\nBut we are always online.", ZiafM3aDA: question2 ?? props.ZiafM3aDA ?? "Who will be my designer or Project Manager?"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;question?: string;answer?: string;question2?: string;answer2?: string;question3?: string;answer3?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Rkc13j3sw, sBCP4a1FS, x8FF61TOf, GjsZVgVKn, ZiafM3aDA, l5idZHupd, DE43HrH2S, eX0r1son7, EY2wqFsIg, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aIkCtC1nG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1tjkan7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aIkCtC1nG"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-9d3c1533-7a94-455c-a0e3-7eab3f651e9c, rgb(250, 251, 249))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><ComponentViewportProvider ><motion.div className={"framer-nmnp9c-container"} layoutDependency={layoutDependency} layoutId={"aapQhYbiW-container"}><Row height={"100%"} id={"aapQhYbiW"} layoutId={"aapQhYbiW"} style={{width: "100%"}} T8rQFvSBR={l5idZHupd} variant={GjsZVgVKn} W88zB8mIG={ZiafM3aDA} width={"100%"}/></motion.div></ComponentViewportProvider><motion.div className={"framer-198j0d4"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"o7cqbSQxz"} style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}/><ComponentViewportProvider ><motion.div className={"framer-o0mppn-container"} layoutDependency={layoutDependency} layoutId={"msVkMH21I-container"}><Row height={"100%"} id={"msVkMH21I"} layoutId={"msVkMH21I"} style={{width: "100%"}} T8rQFvSBR={EY2wqFsIg} variant={DE43HrH2S} W88zB8mIG={eX0r1son7} width={"100%"}/></motion.div></ComponentViewportProvider><motion.div className={"framer-1ks4xsr"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"iIWRPbfLA"} style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}/><ComponentViewportProvider ><motion.div className={"framer-1mghn25-container"} layoutDependency={layoutDependency} layoutId={"wqj6qOirq-container"}><Row height={"100%"} id={"wqj6qOirq"} layoutId={"wqj6qOirq"} style={{width: "100%"}} T8rQFvSBR={x8FF61TOf} variant={Rkc13j3sw} W88zB8mIG={sBCP4a1FS} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DnOdw.framer-1amxolu, .framer-DnOdw .framer-1amxolu { display: block; }", ".framer-DnOdw.framer-1tjkan7 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 20px 0px 20px; position: relative; width: 1000px; will-change: var(--framer-will-change-override, transform); }", ".framer-DnOdw .framer-nmnp9c-container, .framer-DnOdw .framer-o0mppn-container, .framer-DnOdw .framer-1mghn25-container { flex: none; height: auto; position: relative; width: 100%; }", ".framer-DnOdw .framer-198j0d4, .framer-DnOdw .framer-1ks4xsr { flex: none; height: 1px; overflow: hidden; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DnOdw.framer-1tjkan7 { gap: 0px; } .framer-DnOdw.framer-1tjkan7 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-DnOdw.framer-1tjkan7 > :first-child { margin-top: 0px; } .framer-DnOdw.framer-1tjkan7 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 194
 * @framerIntrinsicWidth 1000
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"sBCP4a1FS":"question","x8FF61TOf":"answer","ZiafM3aDA":"question2","l5idZHupd":"answer2","eX0r1son7":"question3","EY2wqFsIg":"answer3"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerhb1UjDgSF: React.ComponentType<Props> = withCSS(Component, css, "framer-DnOdw") as typeof Component;
export default Framerhb1UjDgSF;

Framerhb1UjDgSF.displayName = "Accordion";

Framerhb1UjDgSF.defaultProps = {height: 194, width: 1000};

addPropertyControls(Framerhb1UjDgSF, {sBCP4a1FS: {defaultValue: "Where are your offices located?", displayTextArea: true, title: "Question", type: ControlType.String}, x8FF61TOf: {defaultValue: "We are located in Palma de Mallorca. In Santa Catalina. \nSant Magi 24. 07013.\nAnd we are open for appointments on Fridays only.\nBut we are always online.", displayTextArea: true, title: "Answer", type: ControlType.String}, ZiafM3aDA: {defaultValue: "Who will be my designer or Project Manager?", displayTextArea: true, title: "Question 2", type: ControlType.String}, l5idZHupd: {defaultValue: "Upon subscribing, you will be matched with a top-tier designer. Each designer in our team is personally chosen by our Founder and Creative Director, Luis Curti, boasting over 15 years of expertise in the digital and branding sectors.", displayTextArea: true, title: "Answer 2", type: ControlType.String}, eX0r1son7: {defaultValue: "How do I add these to my Framer site?", displayTextArea: true, title: "Question 3", type: ControlType.String}, EY2wqFsIg: {defaultValue: "If you live in Mallorca it is always good to share a coffee. Tell us when and we will meet. ", displayTextArea: true, title: "Answer 3", type: ControlType.String}} as any)

addFonts(Framerhb1UjDgSF, [{explicitInter: true, fonts: []}, ...RowFonts], {supportsExplicitInterCodegen: true})